<template>
  <div class="row mb-2">

    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <div class="marquee-info" v-if="dataCotacao">
      RFX | {{ dataCotacao }}
    </div>

    <marquee-text v-if="this.variacaoMoedaList.length > 0"
      style="padding-bottom: 0rem !important; background-color: #000;" :duration="110" :repeat="2" :paused="paused">
      <div style="display:flex; padding-top: 8px;">
        <div style="display:flex" v-for="dado in variacaoMoedaList" :key="dado.id">
          <div v-if="dado.variacao">
            <div style="padding: 0px 20px;" @mouseenter="paused = !paused" @mouseleave="paused = false">
              <span>
                {{ dado.tipo_taxa }} {{ dado.moeda }} R$ {{ $util.formatNumber(dado.fator, 2, ',', '.') }}
                <span v-if="dado.variacao >= 0" class="index ml-2" style="padding: 0.08rem 0.5rem !important">+{{ $util.formatNumber(dado.variacao, 2, ',', '.') }}%</span>
                <span v-if="dado.variacao < 0" class="index index-danger ml-2" style="padding: 0.08rem 0.5rem !important">{{ $util.formatNumber(dado.variacao, 2, ',', '.') }}%</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </marquee-text>

    <!-- Processos -->
    <action-card link="/process">
      <template v-slot:icon>
          <span class="er-icon-processos"></span>
      </template>
      <template v-slot:title>
        PROCESSOS
      </template>
      <template v-slot:description>
        Acompanhe a situação atual, data, documentação e outras informações.
      </template>
    </action-card>

    <!-- Cotações -->
    <action-card v-if="isSuperAdmin || isPortalTester || isUserAllowOnlineProposal" link="/proposal">
      <template v-slot:icon>
        <span class="er-icon-cotacoes"></span>
      </template>
      <template v-slot:title>
        COTAÇÕES
      </template>
      <template v-slot:description>
        Faça uma cotação e pré-reserva online.
      </template>
    </action-card>

    <!-- KPIS -->
    <action-card link="/kpi/processes">
      <template v-slot:icon>
        <span class="er-icon-kpis"></span>
      </template>
      <template v-slot:title>
        KPI'S
      </template>
      <template v-slot:description>
        Obtenha um olhar estratégico por meio de dados de todos os seus processos.
      </template>
    </action-card>

    <!-- Financeiro -->
    <action-card link="kpi/finantial/invoices">
      <template v-slot:icon>
        <span class="er-icon-financeiro"></span>
      </template>
      <template v-slot:title>
        FINANCEIRO
      </template>
      <template v-slot:description>
        Acompanhe informações sobre as suas faturas.
      </template>
    </action-card>

    <!-- <button class="btn btn-sm btn-outline-cancel" @click="onShow">Console</button> -->
    <hr class="w-100 mt-0"/>

    <carousel-card v-for="(item, index) in carousels" :key="`carousel${index}`"
      :value="item"
    />

    <link-card v-for="(hiperlink, index) in hiperlinks" v-bind:key="index"
      :value="hiperlink"
      :blank="true"
      :cardClass="actionCardClass"
    />

    <CookieWarning v-if='hasCookie()' />
  </div>
</template>

<script>
import ActionCard from './ActionCard'
import LinkCard from './LinkCard.vue'
import MarqueeText from 'vue-marquee-text-component'
import CookieWarning from '@/components/CookieWarning.vue'
import DashboardService from '@/services/DashboardService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CarouselCard from './CarouselCard.vue'

export default {
  name: 'DashboardV2',
  metaInfo: {
    titleTemplate: 'Dashboard - %s'
  },
  components: {
    Loading,
    ActionCard,
    LinkCard,
    CookieWarning,
    MarqueeText,
    CarouselCard
  },
  data () {
    return {
      paused: false,
      variacaoMoedaList: Object,
      dataCotacao: null,
      isLoading: true,
      fullPage: true,
      hiperlinks: [],
      carousels: [],
      actionCardClass: 'action-card-sm'
    }
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.getDasboardLinks()
  },
  methods: {
    onShow () {
    },
    getDasboardLinks () {
      this.isLoading = true
      DashboardService.getDasboardLinks().then(res => {
        this.hiperlinks = res.data.hiperlinkList
        this.carousels = res.data.carrosselList
        this.variacaoMoedaList = res.data.variacaoMoedaList
        this.dataCotacao = this.$util.formatDateWritten(res.data.dataCotacao)
      // }).catch(function (err) {
      }).finally(() => {
        this.isLoading = false
      })
    },
    hasCookie () {
      let cookies = global.instanceApp.$Cookie.get()
      if (cookies['_consent_']) {
        if (global.instanceApp.$Cookie.get('_consent_') === 1) {
          localStorage.removeItem('_userStoredFilters_')
          global.instanceApp.$Cookie.remove('_userStoredFilters_')
        }
        return false
      } else {
        return true
      }
    }
  },
  computed: {
    isUserAllowOnlineProposal () {
      let userInfo = this.$store.getters.userInfo || null

      if (!userInfo) {
        return false
      } else if (userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      } else if (userInfo.customers_allow_online_proposal_list.length > 0) {
        return true
      }

      return false
    },
    isPortalTester () {
      let status = false
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_PORTAL_TESTER') !== -1) {
        status = true
      }
      return status
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.action-card {
  background-color: #80754E;
}

.index {
  border-radius: 16px;
  background: #13CC71;
  color: rgba(0, 0, 0, 0.56);
  text-align: right;
  font-family: Pluto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.154px;
}

.index-danger {
  background: hsl(0, 100%, 50%);
  color: rgba(255, 255, 255, 0.8);
}

.marquee-info {
  position: absolute;
  z-index: 99;
  padding-left: 14px;
  padding-right: 16px;
  height: 32px;
  line-height: 32px;

  border-radius: 0px 20px 0px 0px;
  background: var(--linha-normal, #E0E0E0);

}

.marquee-text-wrap {
  margin-bottom: 26px;
}

hr {
  /*border: 2px #E0DCD2 solid;*/
  /*padding: 40px;*/
  border: 0;
  border-top: 2px solid #E0DCD2;
}

.content-area {
  background: #FAFAFA;
}

</style>

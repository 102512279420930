<template>
  <div v-if='isActive' class="card-cookie">
    <div class="row" v-if="firstInfo">
      <div class="col-8">
        <div class="text first">
          <p>Os cookies são importantes para o correto funcionamento de um site. Para melhorar a sua experiência, utilizamos cookies para lembrar detalhes da sua sessão e otimizar sua navegação. Clique em Concordar para aceitar os cookies. <span v-on:click="maisInfo"> Mais informações</span></p>
        </div>
        <div class="allow">
          <button v-on:click="accept" class="btn btn-primary btn-fill btn-rounded mb-4 mr-3">Concordar</button>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-7">
        <div class="text more">
          <div class="subinfo">
            <input type="checkbox" checked disabled>
            <span>Cookies Necessários: Estes cookies são necessários para acessar o site.</span>
          </div>
          <div class="subinfo">
            <input type="checkbox" v-model="checked" ref="func_cookies">
            <span>Cookies Funcionais: Estes cookies são para melhorar sua navegação, otimizando as funcionalidades do sistema.</span>
          </div>
        </div>
        <div class="allow">
          <button v-on:click="accept" class="btn btn-primary btn-fill btn-rounded mb-4 mr-3">Aceitar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogsService from '@/services/LogsService'

export default {
  name: 'CookieWarning',
  props: {
    isActive: {
      type: Boolean,
      default: true
    },
    firstInfo: {
      type: Boolean,
      default: true
    },
    moreInfo: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    maisInfo () {
      let _this = this
      _this.firstInfo = false
      _this.moreInfo = true
    },
    accept () {
      let _this = this
      let data = {
        acao: 'cookie',
        description: '2'
      }
      global.instanceApp.$Cookie.set('_consent_', 2, { expires: 365 })
      if (!_this.checked) {
        global.instanceApp.$Cookie.set('_consent_', 1, { expires: 365 })
        localStorage.removeItem('_userStoredFilters_')
        global.instanceApp.$Cookie.remove('_userStoredFilters_')
        data['description'] = 1
      }
      LogsService.sendCookie(data)
      _this.isActive = false
    }
  }
}
</script>

<style scoped>
  .card-cookie{
    position: fixed;
    bottom: 0px;
    left: 0;
    min-height: 120px;
    background: #fff;
    box-sizing: border-box;
    border: solid 1px #877953;
    text-align: center;
    width: 100vw;
  }
  .row{
    text-align: center;
  }
  .col-8, .col-7{
    margin: 15px auto auto;
    position: relative;
  }
  .first span{
    text-decoration: underline;
    cursor: pointer;
  }
  .content-card{
    width: 100%;
  }
  .more{
    margin-bottom: 1em;
  }
  .subinfo{
    text-align: left;
  }
  .subinfo input{
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    margin-bottom: 6px;
  }
  button{
    font-size: 13px;
  }
  @media(max-width: 600px){
    .card-cookie{
      padding-bottom: 45px;
    }
  }
</style>

<template>
  <div class="col-lg-3 col-md-6 m-0 p-3">
    <a :href="link">
      <div class="action-card">
          <div class="action-icon">
              <div>
                <slot name="icon"></slot>
              </div>
          </div>
          <div class="action-content">
            <div>
              <div class="action-title">
                <slot name="title"></slot>
              </div>
              <div class="action-description">
                <slot name="description"></slot>
              </div>
            </div>
          </div>
          <div class="clear"></div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ActionCard',
  data () {
    return {}
  },
  methods: {
  },
  props: {
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.action-card, .action-card * {
  font-size: 12px;
}

.action-card {
  background-color: #80754E;
  color: #FAFAFA;
  border-radius: 8px;
  padding: 22px;
  height: 100%;
}

.action-title {
  font-size: 200%;
  padding-bottom: 8px;
  margin-bottom: 5px !important;
}

.action-icon {
  padding: 0;
  overflow: hidden;
  margin: 0;
  display: block;
  float: left;
  /* width: 50px; */
  height: 100%;
}
.action-content {
  padding: 0;
  margin: 0;
  float: right;
  display: flex;
  width: calc(100% - 60px);
  align-items: center;
  letter-spacing: normal;
}
.action-content div {
  padding: 0;
  margin: 0;
  display: block;
}
.action-icon div {
  display: table;
  height: 100%;
}

.action-icon div span {
  display: table-cell;
  vertical-align: middle;
  font-size: 300%;
}

.action-description {
  font-family: "pluto_sansextralight";

}

.clear {
  display: block;
  clear: both;
}
</style>

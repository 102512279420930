import Http from '@/services/Http'
import BaseService from '@/services/BaseService'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const LogService = {
  sendCookie (dataContent) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/logs`, dataContent).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(() => {
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default LogService
